import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import AutoTab from '../components/AutoTab';
import { View, Text, Pressable, TextInput, StyleSheet, Image, Linking } from "react-native";
import { useState, useCallback, useEffect } from 'react';
import { AutoTabType } from '../constants/autoTabType';
import Footer from '../components/Footer';
import { isMobile } from '../helpers/deviceUtils';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { INPUT_TYPE } from '../constants/inputType';
import axios from 'axios';
import { scale } from 'react-native-size-matters';
import { QuoteInfoType } from '../constants/quoteInfoType';
import { useSearchParams } from 'react-router-dom';
import HeaderAndBackground from '../components/HeaderAndBackground';
import { FlatList } from 'react-native-web';


const Tab = createBottomTabNavigator();

const isMobileDevice = isMobile();

const CarInsuranceInfo = [{
    title: "Hi, let's start with a few questions\nHow big is your travel party?",
    errorMessage: "",
    inputMode: "numeric",
    type: INPUT_TYPE.NUMBER,
    isComplete: false,
    content: "",
},
{
    title: "Where are you looking to travel to and for how long?",
    placeholder: "",
    inputMode: "text",
    isComplete: false,
    content: "",
    maxLength: 200,
},
{
    title: "What is your preference on veichel type?",
    selectedOption: "",
    isComplete: false,
    options: [
        "Sports car",
        "Small SUV",
        "Large SUV",
        "Standard Sedan",
        "Economy",
        "Large people mover"
    ]
},
{
    title: "Do you have any memberships to any major rental agencies? (please list)",
    placeholder: "",
    inputMode: "text",
    isComplete: false,
    content: "",
    maxLength: 200,
},
{
    title: "What date are you looking to start your rental on?",
    placeholder: "",
    inputMode: "text",
    isComplete: false,
    content: "",
    maxLength: 200,
},
{
    title: "How many days will the rental be for?",
    errorMessage: "",
    inputMode: "numeric",
    type: INPUT_TYPE.NUMBER,
    isComplete: false,
    content: "",
},
{
    title: "What is your desired pick up location?",
    placeholder: "",
    inputMode: "text",
    isComplete: false,
    content: "",
    maxLength: 100,
},
{
    title: "What is your desired budget range for the coming trip?",
    errorMessage: "",
    inputMode: "numeric",
    type: INPUT_TYPE.NUMBER,
    isComplete: false,
    content: "",
},
{
    title: "please advise any more information that may help our consulants assist with your enquiry",
    placeholder: "",
    inputMode: "text",
    isComplete: false,
    content: "",
}, {
    title: "Email address",
    placeholder: "Enter your email address",
    inputMode: "email",
    type: INPUT_TYPE.EMAIL,
    errorMessage: "",
    content: "",
},
{
    title: "Phone number",
    placeholder: "(___) ___ ____",
    errorMessage: "",
    inputMode: "tel",
    type: INPUT_TYPE.PHONE,
    content: "",
    maxLength: 20,
},
{}];

function AutoInsuranceTabs({ route, navigation }) {
    const [isShowCompleteView, setIsShowCompleteView] = useState(false);

    const [searchParams] = useSearchParams();
    const zipCode = searchParams.get('zipCode')
    const [infoData, setInfoData] = useState(JSON.parse(JSON.stringify(CarInsuranceInfo)));
    const styles = isMobileDevice ? mobileStyles : desktopStyles;

    const sendQuote = useCallback(async () => {
        let data = ""
        for (let index = 0; index < infoData.length; index++) {
            data += generateQuoteInfo(infoData[index]);
        }

        console.log(data);

        try {

            const response = await axios.post("https://api.emailjs.com/api/v1.0/email/send", {
                service_id: "service_v6jln6h",
                template_id: "template_c5t2puk",
                user_id: "23lB37zM51IUM9_c3",
                template_params: {
                    message: data
                }
            })

            setIsShowCompleteView(true);
            console.log(response)
        } catch (error) {
            console.log(error);
        }
    }, [infoData]);


    const generateQuoteInfo = (item) => {
        if (item.contents) {
            let quoteInfos = "";
            for (let index = 0; index < item.contents.length; index++) {
                const quoteInfo = generateQuoteInfo(item.contents[index]);
                quoteInfos += quoteInfo;
            }

            return quoteInfos;
        }

        const question = item.description || item.title || item.placeholder;
        const answer = item.selectedOption || item.content;

        if (!question) {
            return '';
        }

        return `${question}${question.trim().endsWith("?") ? "" : ":"} ${answer}\n`;
    }

    const onPress = useCallback(() => {
        if (infoData) {
            infoData.forEach((data, index) => {
                if (data.title == null) {
                    return;
                }

                infoData[index].errorMessage = "";
                if (data.selectedOption != null) {
                    if (data.selectedOption === "") {
                        infoData[index].errorMessage = ERROR_MESSAGES.SELECT;
                    }
                    return;
                }

                isDataValid(infoData[index]);
            });

            const errorIndex = infoData.findIndex(data => data.errorMessage != "" && data.errorMessage != null);

            if (errorIndex >= 0) {
                setInfoData([...infoData]);
                return;
            }

            sendQuote();
        }
    }, [infoData]);


    const renderTitle = (title) => {
        return (
            <Text style={styles.title}>{title}</Text>
        )
    }

    const renderDescription = (description, customStyle) => {
        return (
            <Text style={[styles.description, customStyle]}>{description}</Text>
        )
    }

    const renderError = (errorMessage) => {
        return (
            <Text style={[styles.description, { color: "red" }]}>{errorMessage}</Text>
        )
    }

    const renderSubmitButton = () => {

        return (<Pressable style={styles.button}
            onPress={onPress}>
            <Text style={[styles.buttonContent]}>{"SUBMIT"}</Text>
        </Pressable>
        )
    }

    const isDataValid = (item) => {
        let isValid = true;
        if (item.contents) {
            for (let i = 0; i < item.contents.length; i++) {
                isValid = isDataValid(item.contents[i]);
                if (!isValid) {
                    return false;
                }
            }
        }

        item.errorMessage = "";
        if (item.type === INPUT_TYPE.EMAIL) {
            isValid = validator.isEmail(item.content);
            if (!isValid) {
                item.errorMessage = ERROR_MESSAGES.EMAIL;

                return false;
            }
        }

        if (item.type === INPUT_TYPE.PHONE) {
            isValid = validator.isMobilePhone(item.content);
            if (!isValid) {
                item.errorMessage = ERROR_MESSAGES.PHONE;

                return false;
            }
        }

        if (item.type === INPUT_TYPE.ZIP_CODE) {
            isValid = validator.isPostalCode(item.content);
            if (!isValid) {
                item.errorMessage = ERROR_MESSAGES.ZIP_CODE;
                return false;
            }
        }

        if (item.type === INPUT_TYPE.AGE) {
            isValid = validator.isNumeric(item.content) && item.content.length < 4;
            if (!isValid) {
                item.errorMessage = ERROR_MESSAGES.AGE;
                return false;
            }
        }

        if (item.type === INPUT_TYPE.YEAR) {
            isValid = validator.isNumeric(item.content) && item.content.length === 4;
            if (!isValid) {
                item.errorMessage = ERROR_MESSAGES.YEAR;
                return false;
            }
        }

        if (item.type === INPUT_TYPE.NUMBER) {
            isValid = validator.isNumeric(item.content);
            if (!isValid) {
                item.errorMessage = ERROR_MESSAGES.GENERIC;
                return false;
            }
        }

        if (item.content == "") {
            item.errorMessage = ERROR_MESSAGES.GENERIC;
            return false;
        }

        // if (item.type === INPUT_TYPE.DATE) {
        //     isValid = validator.isDate(item.content, {
        //         format: "YYYY/MM/DD",
        //     });
        //     if (!isValid) {
        //         item.errorMessage = ERROR_MESSAGES.DATE;
        //         return false;
        //     }
        // }

        return true;
    }

    const renderOptionButton = (isActive, content, onSelect) => {
        return (
            <Pressable style={[styles.optionButton, isActive && styles.optionButtonActive]}
                onPress={() => onSelect(content)}>
                <Text style={styles.optionButtonContent}>{content}</Text>
            </Pressable>
        )
    }

    const renderTextInput = (data) => {
        return (
            <View style={styles.textInputView}>
                <TextInput placeholder={data.placeholder}
                    style={styles.textInput}
                    onChangeText={(newText) => {
                        data.content = newText;
                        setInfoData([...infoData]);
                    }}
                    inputmode={data.inputMode}
                    value={data.content}
                    maxLength={data.maxLength || 1000}></TextInput>
            </View>
        )
    }

    const renderOptionButtons = (item) => {
        const onSelect = (content) => {
            item.selectedOption = content;
            setInfoData([...infoData]);
        };
        return (
            <View style={styles.columnDirectionView}>
                {item.options.map((data) => renderOptionButton(data === item.selectedOption, data, onSelect))}
            </View>
        )
    };

    const renderViewWithTextInput = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                {item.title && renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.errorMessage && renderError(item.errorMessage)}
                {renderTextInput(item)}
            </View>
        )
    }

    const renderViewWithButtonOptions = (item) => {
        return (
            <View style={[styles.columnDirectionView, { alignItems: "flex-start" }]}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}                {item.errorMessage && renderError(item.errorMessage)}
                {item.errorMessage && renderError(item.errorMessage)}
                {renderOptionButtons(item)}
            </View>
        )
    }

    const renderViewWithMultipleTextInput = (item) => {
        return (
            <View style={styles.columnDirectionView}>
                {renderTitle(item.title)}
                {item.description && renderDescription(item.description)}
                {item.contents.map((data) => renderViewWithTextInput(data))}
            </View>
        )
    }

    const renderCompleteView = () => {
        return (
            <View style={[styles.columnDirectionView, { alignItems: "center" }]}>
                {renderTitle(messages.complete)}
                <Image source={require('../assets/complete.png')}
                    style={styles.completeImage}
                    resizeMode='contain' />
                {renderDescription(messages.completeDescription, { textAlign: "center" })}
            </View>
        )
    }
    const renderListItem = useCallback(({ item }) => {
        if (item.options != null) {
            return renderViewWithButtonOptions(item);
        } else if (item.contents != null) {
            return renderViewWithMultipleTextInput(item);
        } else if (item.content != null) {
            return renderViewWithTextInput(item);
        }

        return renderSubmitButton();
    }, []);

    const renderInfoView = useCallback(() => {
        return (<FlatList data={infoData}
            renderItem={renderListItem}
            keyExtractor={item => item.id}
            style={{ height: "80%" }} />);

    }, [infoData]);


    return (
        <View style={styles.root}>
            <HeaderAndBackground title={'Get Rental Pro'} />
            <View style={styles.detailView}>
                <View style={[styles.detailContent, !isMobileDevice && { width: "40%", minWidth: 600 }]}>
                    {isShowCompleteView ? renderCompleteView()
                        : renderInfoView()}
                </View>
            </View >

            <Footer navigation={navigation}
                style={[{ position: "absolute", marginTop: window.innerHeight * 0.94, flexDirection: "row", justifyContent: "center", width: "100%", alignSelf: "flex-end" }]}>
            </Footer>
        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#86CCFF',
            width: "100%",
            height: "100%",
        },
        title: {
            color: "#111111",
            fontSize: 20,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            marginVertical: 10
        },
        description: {
            color: "#111111",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            marginVertical: 10,
            opacity: 0.75,
        },
        button: {
            borderRadius: 60,
            backgroundColor: "#FE6539",
            flex: 1,
            marginHorizontal: 5,
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 15,
            height: 40,
            width: "25%",
            alignSelf: "flex-end"
        },
        buttonActive: {
            backgroundColor: "#FE6539"
        },
        buttonContent: {
            color: "#FFFFFF",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "700",
            fontFamily: 'Dubai-Regular',
            padding: 14,
        },
        optionButton: {
            marginVertical: scale(5),
            height: scale(40),
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            backgroundColor: "rgba(254, 101, 57, 0.3)",
            flex: 1,
            marginHorizontal: 5,
            alignItems: "center",
            width: "50%"
        },
        optionButtonActive: {
            borderWidth: 2,
            borderColor: "#70E189",
            backgroundColor: "rgba(254, 101, 57, 7)",
        },
        optionButtonContent: {
            color: "#000000",
            fontSize: 14,
            lineHeight: "100%",
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            marginVertical: 15,
            opacity: 0.75
        },
        textInputView: {
            marginVertical: 5,
            width: "100%",
            height: 40,
            flexDirection: "row"
        },
        textInput: {
            borderRadius: 7,
            borderColor: "rgba(11, 60, 73, 0.35)",
            borderWidth: 1,
            fontFamily: 'Dubai-Regular',
            width: "100%",
            paddingHorizontal: 15
        },
        columnDirectionView: {
            flexDirection: "column",
            width: "100%",
            alignItems: "flex-start"
        },
        completeImage: {
            width: "100%",
            height: 400
        },
        detailView: {
            width: "100%",
            marginTop: window.innerHeight * 0.1,
            height: window.innerHeight * 0.8,
            justifyContent: "flex-start",
            alignItems: "center",
            position: "absolute"
        },
        detailContent: {
            width: "90%",
            maxHeight: "80%",
            backgroundColor: "white",
            marginTop: scale(30),
            borderRadius: 10,
            borderColor: "rgba(11, 60, 73, 0.50)",
            borderWidth: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: scale(20)
        },
    });

const desktopStyles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...baseStyles.button,
        width: "25%",
        alignSelf: "flex-end"
    },
})



const mobileStyles = StyleSheet.create({
    ...baseStyles,
    button: {
        ...baseStyles.button,
        width: "100%"
    },
})
const messages = {
    complete: "Complete!",
    completeDescription: "Great, we'll be in touch with some great options!"
}

export default AutoInsuranceTabs;