import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function TermsPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Terms of Use`}</Text>
            <Text style={styles.subHeader}>{`Effective Date: July 10, 2023`}</Text>

            <Text style={styles.description}>{`Welcome to GetRentalPro.com! We're here to make your online experience seamless and enjoyable. By using our website(s) or app(s), collectively referred to as the ""Services,"" you agree to the following terms and conditions. Please take a moment to review them carefully.`}</Text>
            <Text style={styles.title}>{`1. Your Agreement`}</Text>
            <Text style={styles.description}>{`When you use our Services, you affirm that you are at least 18 years old and legally capable of entering into a contract. By accessing or using our Services, you are bound by these Terms of Use. If you don't agree with any part of these terms, you may not access or use our Services.`}</Text>
            <Text style={styles.title}>{`2. Eligibility and Registration`}</Text>
            <Text style={styles.description}>{`Certain features of our Services require you to register for an account. When you do so, you agree to provide accurate and complete information. You're responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.`}</Text>
            <Text style={styles.title}>{`3. Your License`}</Text>
            <Text style={styles.description}>{`We grant you a limited, personal, non-exclusive license to use our Services, provided you comply with these Terms of Use and any additional terms we may set forth. This license is subject to revocation at our discretion. Please refrain from using our Services for commercial purposes.`}</Text>
            <Text style={styles.title}>{`4. Intellectual Property Rights`}</Text>
            <Text style={styles.description}>{`We own all intellectual property associated with our Services. You may not reproduce, modify, or distribute any content or software without our permission.`}</Text>
            <Text style={styles.title}>{`5. Using the Services`}</Text>
            <Text style={styles.description}>{`Please use our Services responsibly. This means refraining from activities that could harm our platform or others, such as introducing malicious software or violating laws.`}</Text>
            <Text style={styles.title}>{`6. Termination or Suspension`}</Text>
            <Text style={styles.description}>{`We reserve the right to suspend or terminate your account if you violate these Terms of Use or engage in activities that may harm our platform or other users.`}</Text>
            <Text style={styles.title}>{`7. Loyalty Program`}</Text>
            <Text style={styles.description}>{`We may offer a loyalty program on select applications. Participation is subject to eligibility requirements.`}</Text>
            <Text style={styles.title}>{`8. User Submissions`}</Text>
            <Text style={styles.description}>{`You're responsible for any content you upload to our Services. By submitting content, you grant us a license to use it as described in these Terms of Use.`}</Text>
            <Text style={styles.title}>{`9. Feedback`}</Text>
            <Text style={styles.description}>{`We welcome your feedback! By providing feedback, you grant us ownership of your suggestions.`}</Text>
            <Text style={styles.title}>{`10. Indemnification`}</Text>
            <Text style={styles.description}>{`You agree to indemnify us for any claims arising from your use of our Services.`}</Text>
            <Text style={styles.title}>{`11. Disclaimer of Warranty`}</Text>
            <Text style={styles.description}>{`Our Services are provided ""as is,"" and we make no warranties regarding their quality or availability.`}</Text>
            <Text style={styles.title}>{`12. Limitation of Liability`}</Text>
            <Text style={styles.description}>{`We're not liable for any indirect or incidental damages arising from your use of our Services.`}</Text>
            <Text style={styles.title}>{`13. Release`}</Text>
            <Text style={styles.description}>{`You release us from liability for disputes between you and third parties related to our Services.`}</Text>
            <Text style={styles.title}>{`14. Governing Law and Arbitration`}</Text>
            <Text style={styles.description}>{`Any disputes will be resolved through arbitration in accordance with the laws of the State of Florida.`}</Text>
            <Text style={styles.title}>{`15. Force Majeure`}</Text>
            <Text style={styles.description}>{`We're not liable for delays or failures caused by events beyond our control.`}</Text>
            <Text style={styles.title}>{`16. Relationship of the Parties`}</Text>
            <Text style={styles.description}>{`These Terms of Use do not create a partnership or agency relationship between us.`}</Text>
            <Text style={styles.title}>{`17. Notice`}</Text>
            <Text style={styles.description}>{`We'll communicate with you electronically. Please keep your contact information up to date.`}</Text>
            <Text style={styles.title}>{`18. Third-Party Links`}</Text>
            <Text style={styles.description}>{`We're not responsible for the content or activities of third-party websites linked to our Services.`}</Text>
            <Text style={styles.title}>{`19. App Distributors`}</Text>
            <Text style={styles.description}>{`Certain areas of our Services may be available through third-party app distributors. We're not liable for their activities.`}</Text>
            <Text style={styles.title}>{`20. Miscellaneous`}</Text>
            <Text style={styles.description}>{`If any part of these Terms of Use is deemed invalid, the remaining provisions will still apply.`}</Text>
            <Text style={styles.title}>{`21. No Unsolicited Submissions`}</Text>
            <Text style={styles.description}>{`Please refrain from submitting unsolicited materials, as we do not accept them.`}</Text>
            <Text style={styles.title}>{`22. Intellectual Property Infringement`}</Text>
            <Text style={styles.description}>{`If you believe your intellectual property rights have been violated, please contact us.`}</Text>
            <Text style={styles.title}>{`23. Questions`}</Text>
            <Text style={styles.description}>{`If you have any questions about these Terms of Use or our Privacy Policy, please contact us at contact@GetRentalPro.com.`}</Text>
            <Text style={styles.description}>{`Thank you for using GetRentalPro.com!`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
        },
    }
)
export default TermsPage;
