import { View, Text, StyleSheet, ImageBackground, Button, Image } from 'react-native';
import { isMobile } from '../helpers/deviceUtils';


function PrivacyPage({ navigation }) {

    return (
        <View style={styles.root}>
            <Text style={styles.header}>{`Privacy Policy`}</Text>
            <Text style={styles.subHeader}>{`Effective Date: July 10, 2023`}</Text>
            <Text style={styles.description}>{`At GetRentalPro.com, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our website(s) or app(s), collectively referred to as the ""Services."" By accessing or using our Services, you agree to the terms of this Privacy Policy.`}</Text>
            <Text style={styles.title}>{`Information We Collect`}</Text>
            <Text style={styles.description}>{`When you use our Services, we may collect certain types of information, including:`}</Text>
            <Text style={styles.subDescription}>{`\t- Personal information: such as your name, email address, and contact details.`}</Text>
            <Text style={styles.subDescription}>{`\t- Usage information: such as your IP address, device information, and browsing activity.`}</Text>
            <Text style={styles.subDescription}>{`\t- User-generated content: such as comments, reviews, or other content you submit to our platform.`}</Text>
            <Text style={styles.title}>{`How We Use Your Information`}</Text>
            <Text style={styles.description}>{`We may use the information we collect for various purposes, including:`}</Text>
            <Text style={styles.subDescription}>{`\t- Providing and improving our Services.`}</Text>
            <Text style={styles.subDescription}>{`\t- Communicating with you about your account or our products and services.`}</Text>
            <Text style={styles.subDescription}>{`\t- Customizing your experience on our platform.`}</Text>
            <Text style={styles.subDescription}>{`\t- Analyzing trends and user behavior to enhance our offerings.`}</Text>
            <Text style={styles.subDescription}>{`\t- Complying with legal obligations.`}</Text>
            <Text style={styles.title}>{`Information Sharing`}</Text>
            <Text style={styles.description}>{`We may share your information in the following circumstances:`}</Text>
            <Text style={styles.subDescription}>{`\t- With third-party service providers who assist us in operating our platform or providing services to you.`}</Text>
            <Text style={styles.subDescription}>{`\t- With our affiliates or business partners in connection with the services they provide.`}</Text>
            <Text style={styles.subDescription}>{`\t- In response to legal requests or to protect our rights or the rights of others.`}</Text>
            <Text style={styles.title}>{`Your Choices`}</Text>
            <Text style={styles.description}>{`You have certain choices regarding the information we collect and how it's used:`}</Text>
            <Text style={styles.subDescription}>{`\t- You can update or correct your personal information through your account settings.`}</Text>
            <Text style={styles.subDescription}>{`\t- You can opt out of receiving promotional communications from us by following the instructions provided in such communications.`}</Text>
            <Text style={styles.subDescription}>{`\t- You can disable cookies in your browser settings, although this may affect your ability to use certain features of our Services.`}</Text>
            <Text style={styles.title}>{`Data Security`}</Text>
            <Text style={styles.description}>{`We take reasonable measures to protect your information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.`}</Text>
            <Text style={styles.title}>{`Children's Privacy`}</Text>
            <Text style={styles.description}>{`Our Services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us so we can delete it.`}</Text>
            <Text style={styles.title}>{`Third-Party Links`}</Text>
            <Text style={styles.description}>{`Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to read the privacy policies of any third-party sites you visit.`}</Text>
            <Text style={styles.title}>{`Changes to This Policy`}</Text>
            <Text style={styles.description}>{`We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised effective date. We encourage you to review this Privacy Policy periodically for updates.`}</Text>
            <Text style={styles.title}>{`Contact Us`}</Text>
            <Text style={styles.description}>{`If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at contact@GetRentalPro.com.`}</Text>
            <Text style={styles.description}>{`Thank you for trusting GetRentalPro.com with your personal information.`}</Text>

        </View>
    );
}

const styles = StyleSheet.create(
    {
        root: {
            backgroundColor: 'white',
            padding: 50
        },
        header: {
            color: "#0B3C49",
            fontSize: 22,
            fontWeight: "700",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        subHeader: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
            textAlign: "center",
        },
        title: {
            color: "#0B3C49",
            fontSize: 20,
            fontWeight: "600",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        description: {
            color: "#0B3C49",
            fontSize: 16,
            fontWeight: "500",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 10,
        },
        subDescription: {
            color: "#0B3C49",
            fontSize: 14,
            fontWeight: "400",
            fontFamily: 'Dubai-Regular',
            position: "relative",
            paddingVertical: 5,
        },
    }
)

export default PrivacyPage;
