import { View, Text, StyleSheet, Linking } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../helpers/deviceUtils';
import { Image } from 'react-native-web';


const isMobileDevice = isMobile();

const HeaderAndBackground = (props) => {
    const styles = isMobileDevice ? mobileStyles : desktopStyles;

    return (
        <View style={styles.root}>
            <Image source={require('../assets/car-driving-backdrop.gif')}
                style={styles.image} />

            <View style={styles.header}>
                <View style={styles.headerContent}>
                    <Text style={styles.title}>{props.title}</Text>
                </View>
            </View>
        </View>);
}

const styles = StyleSheet.create(
    {
        footer: {
            color: "white",
            fontSize: 16,
            fontWeight: "500",
            position: "relative"
        },
    }
)

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
            height: window.innerHeight,
        },
        header: {
            height: window.innerHeight * 0.2,
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
        },
        title: {
            color: "#FFFFFF",
            fontSize: 64,
            lineHeight: 66,
            fontFamily: 'Dubai-Bold',
        },
        image: {
            width: "100%",
            height: '100%',
            resizeMode: 'cover',
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        header: {
            ...baseStyles.header,
            width: '100%',
        },
        title: {
            ...baseStyles.title,
            fontSize: 64,
            lineHeight: 66,
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        header: {
            ...baseStyles.header,
            width: window.innerWidth,
        },
        headerContent: {
            marginLeft: "10%",
            flexDirection: "row",
            alignItems: "center"
        },
    }
)

export default HeaderAndBackground;