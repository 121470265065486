import { View, Text, StyleSheet, ImageBackground, Button, TextInput } from 'react-native';
import { useState } from 'react';
import Footer from '../components/Footer';
import validator from 'validator';
import { ERROR_MESSAGES } from '../constants/errorMessages';
import { isMobile } from '../helpers/deviceUtils';
import { Image, Pressable } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import HeaderAndBackground from '../components/HeaderAndBackground';

const isMobileDevice = isMobile();


function AutoHomePage({ navigation }) {
    const [zipCode, setZipCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const styles = isMobileDevice ? mobileStyles : desktopStyles;
    const navigate = useNavigate()

    return (
        <View style={styles.root}>
            <HeaderAndBackground title={messages.title} />


            <View style={styles.mainContent} >
                <Text style={styles.description}>{messages.description}</Text>
                {errorMessage && <Text style={styles.error}>{errorMessage}</Text>}

                <TextInput style={styles.textInput}
                    placeholder={messages.zipCode}
                    placeholderTextColor={'rgba(25,28,31,0.5)'}
                    value={zipCode}
                    inputMode='numeric'
                    maxLength={5}
                    onChangeText={(newValue) => {
                        setZipCode(newValue);
                    }} />


                <Pressable style={[styles.buttonView]}
                    onPress={() => {
                        const isValid = validator.isPostalCode(zipCode, "US");
                        if (isValid) {
                            setErrorMessage("");
                            navigate({
                                pathname: '/info',
                                search: '?zipCode=' + zipCode,
                            })
                        } else {
                            setErrorMessage(ERROR_MESSAGES.ZIP_CODE)
                        }
                    }}>
                    <Text style={[styles.buttonContent]}>{messages.seeQuotes}</Text>
                </Pressable>
            </View>
            <Footer navigation={navigation} style={styles.footer}>

            </Footer>

        </View>
    );
}

const baseStyles = StyleSheet.create(
    {
        root: {
            backgroundColor: '#91E9EC',
            height: window.innerHeight,
        },
        mainContent: {
            top: window.innerHeight * 0.2,
            width: '100%',
            flexDirection: 'row',
            justifyContent: "center",
            alignItems: "center",
            position: "absolute"
        },
        description: {
            color: "white",
            fontFamily: 'Dubai-Bold',
        },
        error: {
            color: "red",
            fontFamily: 'Dubai-Regular',
            lineHeight: 24
        },
        textInput: {
            backgroundColor: "white",
            borderRadius: 10,
            fontFamily: 'Dubai-Regular',
            padding: 15,
            height: 40,
            marginLeft: 20,
        },
        footer: {
            position: "absolute",
            marginTop: window.innerHeight * 0.94,
            flexDirection: "row",
            justifyContent: "center",
            width: "100%"
        },
        buttonView: {
            backgroundColor: "#FE6539",
            marginLeft: 20,
            position: "relative",
            borderRadius: 10,
            paddingVertical: 5,
            paddingHorizontal: 15,
            height: 40,
            fontWeight: "800",
            alignItems: "center",
            justifyContent: "center",
        },
        buttonContent: {
            color: "#000000",
            fontSize: 16,
            lineHeight: "100%",
            fontFamily: 'Dubai-Bold',
            padding: 14,
        },
    }
)

const desktopStyles = StyleSheet.create(
    {
        ...baseStyles,
        description: {
            ...baseStyles.description,
            fontSize: 30
        },
        error: {
            ...baseStyles.error,
            fontSize: 24,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 12,
            width: 220
        },
    }
)


const mobileStyles = StyleSheet.create(
    {
        ...baseStyles,
        root: {
            ...baseStyles.root,
            height: "100%",
            width: '100%',
        },
        mainContent: {
            ...baseStyles.mainContent,
            marginLeft: "10%",
            width: "80%",
            position: "absolute",
            marginTop: 65,
            paddingRight: 20
        },
        description: {
            ...baseStyles.description,
            fontSize: 30,
            lineHeight: 38
        },
        error: {
            ...baseStyles.error,
            fontSize: 20,
        },
        textInput: {
            ...baseStyles.textInput,
            fontSize: 10,
            width: "60%"
        },
    }
)

const messages = {
    title: "Get the best rental deal from the pros!",
    description: "ENTER YOUR ZIP TO GET STARTED",
    zipCode: "ZIP",
    seeQuotes: "GO!",
}

export default AutoHomePage;
